import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { IVestingEvent } from '@app/esop/plan-management/types';
import { ApexOptions } from 'ng-apexcharts';

import { ReplaySubject } from 'rxjs';

interface ChartDataPoint {
  x: string;
  y: number;
}

interface ChartSeries {
  name: 'Vested' | 'Cliff Period' | 'Unconditional Vested';
  data: ChartDataPoint[];
}

const frequencyMap: Record<string, string> = {
  M: 'Month',
  Y: 'Year',
  Q: 'Quarter',
};

const frequencyPrefixMap: Record<string, string> = {
  monthly: 'M',
  quarterly: 'Q',
  yearly: 'Y',
};

const seriesColorMap: Record<string, string> = {
  Vested: '#008FFB',
  'Cliff Period': '#FF69B4',
  'Unconditional Vested': '#12b0ca',
};

@Component({
  selector: 'wevestr-vesting-schedule-chart-no-date',
  templateUrl: './vesting-schedule-chart-no-date.component.html',
  styleUrls: ['./vesting-schedule-chart-no-date.component.scss'],
})
export class VestingScheduleChartNoDateComponent implements OnChanges {
  @ViewChild('chartGrid')
  public apxChart: ElementRef;
  private _vestingType: string;

  @Input()
  set vestingType(value: string) {
    if (this._vestingType !== value) {
      this._vestingType = value;
      this.reinitializeChart();
    }
  }

  get vestingType(): string {
    return this._vestingType;
  }

  @Input() cliffPeriod: { value: number; type: string };
  @Input() frequency: string;
  @Input() direction: 'forward' | 'reverse';
  @Input() distribution: 'RoundUpFirst' | 'RoundUpLast';
  @Input() vestingSchedules: IVestingEvent[];
  @Input() vestingEvents?: number;

  public chartOptions: Partial<ApexOptions> = {};
  public vestingSeries: { x: string; y: number }[] = [];
  public cliffPeriodSeries: { x: string; y: number }[] = [];
  public unconditionalVestedScheduleSeries: { x: string; y: number }[] = [];
  public xAxisLabels$: ReplaySubject<string[]> = new ReplaySubject();
  public numOfEvents: number;

  constructor() {}

  public ngOnInit(): void {
    this.numOfEvents = this.vestingEvents || this.vestingSchedules.length;
  }

  public ngOnChanges(): void {
    this.numOfEvents = this.vestingEvents || this.vestingSchedules.length;

    this.generateChartSeries();
  }

  /* eslint-disable max-lines-per-function */
  /* eslint-disable complexity */
  private generateChartSeries(): void {
    // Normalize cliff period and vesting schedules
    const cliffDuration = this.normalizeCliffPeriod(this.cliffPeriod);
    const normalizedSchedules = this.normalizeVestingSchedules(this.vestingSchedules);

    const labels = this.generateXAxisLabels(this.cliffPeriod, normalizedSchedules);

    // Clear existing chart series
    this.vestingSeries = [];
    this.cliffPeriodSeries = [];
    this.unconditionalVestedScheduleSeries = [];

    // Cliff Period Series
    if (cliffDuration >= 0) {
      for (let i = 0; i <= cliffDuration; i++) {
        this.cliffPeriodSeries.push({
          x: labels[i],
          y: 0,
        });
      }
    }

    // Handle different vesting types
    switch (this.vestingType) {
      case 'linear':
        this.handleLinearVesting(labels, cliffDuration);
        break;
      case 'one_off':
        this.handleOneOffVesting(labels, cliffDuration);
        break;
      case 'custom':
        this.handleCustomVesting(labels, cliffDuration, normalizedSchedules);
        break;
    }

    this.xAxisLabels$.next(labels);
    this.initChartOptions();
  }

  // Helper function for linear vesting
  private handleLinearVesting(labels: string[], cliffDuration: number): void {
    const allocationPerEvent = 100 / this.numOfEvents; // Allocation spread across numOfEvents only
    const startDuration = cliffDuration > 0 ? cliffDuration : 0;
    let cumulativeAllocation = 0;

    // Determine which series to push data to based on direction
    const targetSeries = this.direction === 'reverse' ? this.unconditionalVestedScheduleSeries : this.vestingSeries;

    // Add cliff period
    for (let i = 0; i < cliffDuration; i++) {
      targetSeries.push({
        x: labels[i],
        y: 0,
      });
    }

    // Add vesting schedule
    for (let i = startDuration; i < this.numOfEvents + startDuration; i++) {
      cumulativeAllocation += allocationPerEvent;

      targetSeries.push({
        x: labels[i],
        y: Math.min(Math.max(cumulativeAllocation, 0), 100), // Clamp between 0 and 100
      });
    }

    if (this.direction === 'reverse') {
      targetSeries.forEach((series) => {
        this.vestingSeries.push({
          x: series.x,
          y: 100,
        });
      });
    }
  }

  // Helper function for one-off vesting
  private handleOneOffVesting(labels: string[], cliffDuration: number): void {
    // Add cliff period
    for (let i = 0; i < cliffDuration; i++) {
      this.vestingSeries.push({
        x: labels[i],
        y: 0, // No allocation during cliff period
      });
    }

    // Add the one-off vesting event (regardless of cliffDuration)
    const vestingIndex = cliffDuration < labels.length ? cliffDuration : 0;

    this.vestingSeries.push({
      x: labels[vestingIndex],
      y: 100, // Full allocation after the cliff period (or immediately if no cliff)
    });

    for (let i = vestingIndex + 1; i < labels.length; i++) {
      this.vestingSeries.push({
        x: labels[i],
        y: 100, // Continue showing full allocation after vesting event
      });
    }
  }

  // Helper function for custom vesting
  private handleCustomVesting(labels: string[], cliffDuration: number, normalizedSchedules: IVestingEvent[]): void {
    let cumulativeDuration = cliffDuration;
    let cumulativeAllocation = 0;

    // Add cliff period
    for (let i = 0; i < cliffDuration; i++) {
      this.vestingSeries.push({
        x: labels[i],
        y: 0,
      });
    }

    // Add custom vesting schedules
    let lastPrefix = ''; // Track last frequency type for correct numbering

    // Process Each Vesting Schedule
    normalizedSchedules.forEach((schedule, index) => {
      const { allocation, vestingDuration } = schedule;
      const scheduleLabels = labels.slice(cumulativeDuration, cumulativeDuration + vestingDuration);

      scheduleLabels.forEach((label, idx) => {
        const isLastInSchedule = idx === scheduleLabels.length - 1;
        const allocationPerEvent = allocation / vestingDuration;
        cumulativeAllocation += isLastInSchedule ? allocation : allocationPerEvent;

        // Handle frequency change case (merge last vesting label)
        const currentPrefix = label.includes('/') ? label.split('/')[1].charAt(0) : label.charAt(0);
        if (index > 0 && currentPrefix !== lastPrefix) {
          this.vestingSeries[this.vestingSeries.length - 1].x += `/${label}`; // Merge labels
        } else {
          this.vestingSeries.push({
            x: label, // Use merged labels like "M5/Y1"
            y: Math.min(cumulativeAllocation, 100),
          });
        }

        lastPrefix = currentPrefix;
      });

      cumulativeDuration += vestingDuration;
    });
  }

  private normalizeCliffPeriod(cliff: { value: number; type: string }): number {
    return cliff?.value || 0;
  }

  private normalizeVestingSchedules(schedules: IVestingEvent[]): IVestingEvent[] {
    if (this.vestingType === 'linear') {
      const baseAllocation = 100 / this.numOfEvents;
      const allocations = Array.from({ length: this.numOfEvents }, () => baseAllocation);

      // Handle distribution: RoundUpFirst or RoundUpLast
      const totalAllocated = allocations.reduce((sum, val) => sum + val, 0);
      const adjustment = 100 - totalAllocated;

      if (adjustment !== 0) {
        if (this.distribution === 'RoundUpFirst') {
          allocations[0] += adjustment; // Add remaining allocation to the first event
        } else if (this.distribution === 'RoundUpLast') {
          allocations[allocations.length - 1] += adjustment; // Add to the last event
        }
      }

      return allocations.map((portion) => ({
        allocation: portion,
        vestingDuration: 1,
        vestingFrequency: this.frequency.toLowerCase(), // Use the main frequency
      }));
    }

    if (this.vestingType === 'one_off') {
      return [
        {
          allocation: 100,
          vestingDuration: 1,
          vestingFrequency: 'monthly',
        },
      ];
    }

    // For custom vesting, retain existing schedules
    if (this.vestingType === 'custom') {
      return schedules.flatMap((schedule) => {
        const { allocation, vestingDuration, vestingFrequency } = schedule;
        const lowerFrequency = vestingFrequency?.toLowerCase() || 'monthly';
        const baseAllocation = allocation / vestingDuration;

        const allocations = Array.from({ length: vestingDuration }, () => baseAllocation);

        // Handle distribution: RoundUpFirst or RoundUpLast
        const totalAllocated = allocations.reduce((sum, val) => sum + val, 0);
        const adjustment = allocation - totalAllocated;

        if (adjustment !== 0) {
          if (this.distribution === 'RoundUpFirst') {
            allocations[0] += adjustment; // Add remaining allocation to the first event
          } else if (this.distribution === 'RoundUpLast') {
            allocations[allocations.length - 1] += adjustment; // Add to the last event
          }
        }

        // Create vesting events based on the allocations
        return allocations.map((portion) => ({
          allocation: portion,
          vestingDuration: 1,
          vestingFrequency: lowerFrequency,
        }));
      });
    }

    return schedules;
  }

  private generateXAxisLabels(
    cliffPeriod: { value: number; type: string },
    vestingSchedules: IVestingEvent[],
  ): string[] {
    const labels: string[] = [];
    const labelTracker: Record<string, number> = {};

    // Determine the prefix for the cliff period
    const cliffPrefix = frequencyPrefixMap[cliffPeriod?.type?.toLowerCase()] || '';
    const cliffDuration = cliffPeriod?.value || 0; // Default to 0 if undefined

    // Generate Cliff Period Labels (e.g., Q0, Q1, Q2)
    if (cliffPrefix) {
      for (let i = 0; i <= cliffDuration; i++) {
        const label = `${cliffPrefix}${i}`;
        labelTracker[label] = 1; // Track cliff labels
        labels.push(label);
      }
    }

    // Determine the prefix for vesting (fallback if cliff is missing)
    const firstVestingFrequency = vestingSchedules[0]?.vestingFrequency?.toLowerCase() || 'monthly';
    const vestingPrefix = frequencyPrefixMap[firstVestingFrequency] || 'M'; // Default to 'M'

    if (this.vestingType === 'custom') {
      let lastPrefix = cliffPrefix || vestingPrefix;
      let vestingCounter = 1;

      // Generate Vesting Period Labels based on original vestingFrequency
      vestingSchedules.forEach((schedule, index) => {
        const currentPrefix = schedule.vestingFrequency
          ? frequencyPrefixMap[schedule.vestingFrequency.toLowerCase()] || 'M'
          : vestingPrefix;

        let startIndex;

        if (cliffPeriod?.value) {
          startIndex = currentPrefix === lastPrefix ? vestingCounter : 1;
        } else {
          startIndex = currentPrefix === lastPrefix ? vestingCounter : 1;
        }

        // Reset counter if frequency changes
        if (currentPrefix !== lastPrefix) {
          vestingCounter = 1;
          startIndex = 1;
        }

        let label = `${currentPrefix}${startIndex}`;
        // Check for duplicates and append a whitespace if label already exists
        if (labelTracker[label]) {
          label += '\u00A0'; // Append non-breaking space to make it unique
        }
        labelTracker[label] = (labelTracker[label] || 0) + 1;

        if (index === 0 && cliffDuration > 0 && currentPrefix !== cliffPrefix) {
          // Merge last cliff label with the first vesting label
          labels[labels.length - 1] = `${labels[labels.length - 1]}/${label}`;
        } else {
          labels.push(label);
        }

        vestingCounter++; // Increment for next label
        lastPrefix = currentPrefix;
      });
    } else {
      let hasDifferentPrefix = false;
      let currentPrefix = cliffPrefix || vestingPrefix;
      let lastIndex = 0;

      // Generate Vesting Period Labels based on original vestingFrequency
      vestingSchedules.forEach((schedule, index) => {
        currentPrefix = schedule.vestingFrequency
          ? frequencyPrefixMap[schedule.vestingFrequency.toLowerCase()] || 'M'
          : vestingPrefix;

        let startIndex;

        if (cliffPeriod?.value) {
          startIndex =
            currentPrefix === cliffPrefix
              ? cliffDuration + index + 1 // Reuse last cliff period label
              : index + 1; // Start fresh from 1 if different frequency
        } else {
          startIndex = index; // No cliff period, start from 0
        }

        if (index === 0 && cliffDuration > 0 && currentPrefix !== cliffPrefix) {
          // Merge the last cliff label with the first vesting label
          labels[labels.length - 1] = `${labels[labels.length - 1]}/${currentPrefix}${startIndex}`;
          hasDifferentPrefix = true;
          lastIndex = startIndex + 1;
        } else {
          labels.push(`${currentPrefix}${startIndex}`);
          lastIndex = startIndex;
        }
      });

      if (hasDifferentPrefix && this.vestingType === 'one_off') {
        labels.push(`${currentPrefix}${lastIndex}`);
      }
    }

    return labels;
  }

  private reinitializeChart(): void {
    // Clear existing data
    this.vestingSeries = [];
    this.cliffPeriodSeries = [];
    this.unconditionalVestedScheduleSeries = [];
    this.chartOptions = {};

    // Re-generate chart data
    this.generateChartSeries();
  }

  // eslint-disable-next-line max-lines-per-function
  private initChartOptions(): void {
    // Build the series array conditionally
    const chartSeries: ChartSeries[] = [
      {
        name: 'Vested',
        data: this.vestingSeries,
      },
    ];

    // Add Cliff Period only if it exists
    if (this.cliffPeriodSeries && this.cliffPeriodSeries.length > 0) {
      chartSeries.push({
        name: 'Cliff Period',
        data: this.cliffPeriodSeries,
      });
    }

    if (this.unconditionalVestedScheduleSeries && this.unconditionalVestedScheduleSeries.length > 0) {
      chartSeries.push({
        name: 'Unconditional Vested',
        data: this.unconditionalVestedScheduleSeries,
      });
    }

    const categories = this.vestingSeries.map((point) => point.x);
    const colors = chartSeries.map((s) => seriesColorMap[s.name]);
    const opacities = chartSeries.map((s) => {
      if (s.name === 'Cliff Period') {
        return 0;
      }
      if (s.name === 'Vested') {
        return this.direction === 'reverse' ? 0 : 0.5;
      }
      return 0.5;
    });

    this.chartOptions = {
      series: chartSeries,
      chart: {
        type: 'area',
        height: 250,
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'stepline',
        width: [3, 3],
      },
      fill: {
        type: 'solid',
        opacity: opacities,
      },
      plotOptions: {
        area: {
          fillTo: 'origin',
        },
      },
      xaxis: {
        categories: categories,
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: 120,
        tickAmount: 6,
        show: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        x: {
          formatter: (_value: string | number, { dataPointIndex, w }) => {
            const labels = w.globals.categoryLabels;
            const label = labels[dataPointIndex];

            if (!label) {
              return 'Unknown';
            }

            if (label.includes('/')) {
              const parts = label.split('/');
              const formattedParts = parts.map((part: string) => {
                const prefix = part.charAt(0);
                const number = part.match(/\d+/)?.[0];
                const frequencyLabel = frequencyMap[prefix] || 'Period';

                return `${frequencyLabel} ${number}`;
              });

              return formattedParts.join(' / ');
            }

            const prefix = label.charAt(0);
            const number = label.match(/\d+/)?.[0];
            const frequencyLabel = frequencyMap[prefix] || 'Period';

            return `${frequencyLabel} ${number}`;
          },
        },
        y: {
          formatter: (value: number) => `${value?.toFixed(2)}%`,
        },
        enabledOnSeries: [0],
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        inverseOrder: true,
        markers: {
          width: 14,
          height: 14,
          offsetY: 2,
        },
      },
    };
  }
}
