<app-table-empty-state
  class="margin-top-m empty-state"
  *ngIf="vestingSeries.length === 0; else chart"
  title="Vesting schedule is empty"
  description="Please add some data"
></app-table-empty-state>

<ng-template #chart>
  <div class="chart-container">
    <span>Vesting Schedule</span>

    <div #chartGrid>
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [markers]="chartOptions.markers"
        [stroke]="chartOptions.stroke"
        [colors]="chartOptions.colors"
        [fill]="chartOptions.fill"
        [dataLabels]="chartOptions.dataLabels"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [grid]="chartOptions.grid"
        [legend]="chartOptions.legend"
        [tooltip]="chartOptions.tooltip"
      ></apx-chart>
    </div>
  </div>
</ng-template>
